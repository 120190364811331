<template>
  <div class="flex-grow-1 pb-12">
    <AdminTitle title="Jobs">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <!-- <v-btn
            @click="getUsers()"
            :loading="status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-btn
            @click="showAddEditDialog()"
            color="accent gradient"
            dark
          >
            Add New User
          </v-btn> -->

          <!-- <v-btn color="primary" outlined>
            Export CSV
          </v-btn> -->
        </div>
      </template>
    </AdminTitle>

    <div>
      <!-- FILTERS -->
      <div class="d-flex justify-space-between align-center mb-10">
        <div v-if="status.displayRange">
          Displaying <span class="font-weight-bold">{{ jobs.length }} New Jobs</span> in the last {{ status.displayRange }} Days
        </div>
        <div v-else>
          Displaying All <span class="font-weight-bold">{{ jobs.length }} Jobs</span>
        </div>

        <div class="d-flex gap-12 align-center">

          <v-sheet max-width="250">
            <v-select
              v-model="status.displayRange"
              :items="displayRanges"
              item-text="name"
              item-value="value"
              prefix="Display: "
              hide-details
              class="dashboard-select"
              dense
            ></v-select>
          </v-sheet>

          <v-sheet max-width="250">
            <v-select
              v-model="order"
              :items="orderBy"
              item-text="name"
              item-value="value"
              prefix="Sort by: "
              hide-details
              class="dashboard-select"
              dense
            ></v-select>
          </v-sheet>

          <v-btn-toggle
          mandatory
          >
            <v-btn small icon
            @click="changeView('list')"
            >
              <v-icon small>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
            <v-btn small icon
            @click="changeView('grid')"
            >
              <v-icon small>mdi-view-grid</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>

      <template v-if="status.getting">
        <preloader/>
      </template>

      <!-- CONTENT -->
      <v-card class="shadow-lg" v-if="!status.getting">
        <v-card-text>
          <v-alert v-if="!jobs.length" type="info" border="left" class="mb-0">No jobs found.</v-alert>
          
          <v-simple-table v-if="jobs.length && this.listView == 'list'">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Private</th>
                  <th>Remote</th>
                  <th>Location</th>
                  <th>Monthly Salary</th>
                  <th>Hourly Salary</th>
                  <th>Status</th>
                  <th class="text-center">Job Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="job in ordered" :key="job.id">
                  <td>
                    <div class="d-flex align-center">
                      <JobPhoto tile :size="30" :id="job.schoolId" class="mr-3" />
                      <span class="font-weight-bold">{{ job.title }}</span>
                    </div>

                  </td>
                  <td>{{ job.private }}</td>
                  <td>{{ job.remote }}</td>
                  <td>{{ job.location }}</td>
                  <td>{{ job.monthlySalary }}</td>
                  <td>{{ job.hourlySalary }}</td>
                  <td>{{ job.status }}</td>
                  <td class="text-caption text-center grey--text">{{ job.createdAt | fromNow }}</td>
                  <td width="15" class="text-right">
                    <v-menu bottom left offset-y transition="slide-x-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          small
                        >
                          <v-icon small>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item class="subtle" dense :to="{ name: 'JobProfile', params: { id: job.id } }">
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-note-text-outline</v-icon> View</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-note-outline</v-icon> Edit</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item class="subtle" dense :to="{ name: 'JobUpdate', params: { id: job.id } }">
                          <v-list-item-title><v-icon color="red" left small>mdi-note-minus-outline</v-icon> <span class="red--text">Delete</span> </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <!-- <div v-if="!status.getting">
        <template v-if="users.length && this.listView == 'grid'">
          <v-row>
            <v-col cols="3" v-for="user in ordered" :key="user.id">
              <v-card outlined hover>
                <v-card-text class="fill-height align-center">
                  <div>
                    <div class="d-flex justify-space-between">
                      <UserPhoto :size="90" photoSize="thumb" :id="user.userid" />
                      <v-chip label x-small :color="getLabelColor(user.role)" >{{ user.role }}</v-chip>
                    </div>
                      <div class="d-flex justify-space-between">
                        <h5 class="body-2 font-weight-bold pt-3">{{ user.fullName }}</h5>
                      </div>
                      <div class="d-flex justify-space-between">
                        <a :href="`mailto: ${user.email}`" class="text-decoration-none">{{ user.email }}</a>
                      </div>

                  </div>
                </v-card-text>
                <v-card-actions class="grey lighten-4 align-center">
                  <div>
                      <v-icon left small>mdi-calendar-month-outline</v-icon> <span class="caption">{{ user.createdAt | fromNow }}</span>
                  </div>
                  <v-spacer></v-spacer>
                  <div>
                    <v-menu bottom right offset-y transition="slide-x-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          small
                        >
                          <v-icon small>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-account-settings-outline</v-icon> View</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showAddEditDialog(user)" class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-account-edit-outline</v-icon> Edit</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="deleteUser(user)" class="subtle" dense>
                          <v-list-item-title><v-icon color="red" left small>mdi-delete-outline</v-icon> <span class="red--text">Delete</span> </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </div> -->

    </div>

  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'Jobs',

  metaInfo: {
    title: 'Jobs'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      order: 'createdAt',
      listView: 'list',
      displayRanges: [
        {
          name: 'Last 60 Days',
          value: 60
        },
        {
          name: 'Last 30 Days',
          value: 30
        },
        {
          name: 'Last 7 Days',
          value: 7
        },
        {
          name: 'All',
          value: 0
        },
      ],

      orderBy: [
        {
          name: 'Date Created',
          value: 'createdAt'
        },
        {
          name: 'Name',
          value: 'fullName'
        }
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      jobs: state => state.jobs.adminJobs,
      status: state => state.jobs.status,
    }),

    ordered: function () {
      let orderType = this.order == 'createdAt' ? 'desc' : 'asc'

      return _orderBy(this.jobs, this.order, orderType)
    }

  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('jobs', [
      'getJobsAdmin',
    ]),

  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    if (!this.status.firstLoad) this.getJobsAdmin()
  }

}

</script>

<style media="screen">

</style>
